import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

const BusinessPage = ({ data }) => (
  <Layout activePage="business">
    <SEO title="Business" keywords="Frank Kürzel, Model, Business" />
    <div className="card-columns">
      {data.allFile.edges.map(({ node }, index) => (
        <div className="card" key={index}>
          <Image imgName={node.base} />
        </div>
      ))}
    </div>
  </Layout>
)

export default BusinessPage

export const query = graphql`
  {
    allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativePath: { regex: "/business/" }
      }
    ) {
      edges {
        node {
          base
        }
      }
    }
  }
`
